import React, { useEffect } from "react";

// For multi lingual conversion
import { FormattedMessage } from "react-intl";

// For getting redirect URL from query params
import { useLocation } from "react-router-dom";

// For setting redirect URL
import Cookies from "universal-cookie";

// Dropdown to choose language
import LanguagePicker from "../LanguagePicker";

// SMS or e-mail selector
import LoginTabs from "./LoginTabs";

// When there are multiple accounts found for a phone number or email,
// display them for user to choose
import LoginAccounts from "./LoginAccounts";

// import LoginOthers from "./LoginOthers.js";
// Error alert
import LoginError from "./LoginError";

// Success alert
import LoginSuccess from "./LoginSuccess";
import SP_API from "../../components/API";

// For parsing the redirect URL, before redirecting
const Url = require("url-parse");

// Config file to get the redirect URL if not specified
const config = require("../../config/config.json");

// Getting full URL of current page
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Login() {
  // Get / set accounts list when multiple accounts are found
  const [accounts, setAccounts] = React.useState([]);

  // Get / set redirecting state before leaving the auth page
  const [successRedirecting, setSuccessRedirecting] = React.useState(false);

  // Get / set the message for the error alert box
  const [errorMessage, setErrorMessage] = React.useState("");
  const [tabErrorMessage, setTabErrorMessage] = React.useState({});

  // Get / set the message for the success alert box
  const [successMessage, setSuccessMessage] = React.useState("");

  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [OTP, setOTP] = React.useState(false);
  const [sendingOTP, setSendingOTP] = React.useState(false);
  const [OTPrequestID, setOTPrequestID] = React.useState(null);
  const [validatingOTP, setValidatingOTP] = React.useState(false);
  const [redirectURL, setRedirectURL] = React.useState(config.websiteUrl);
  const [provider, setProvider] = React.useState("");
  const [isSSOLogin, setIsSSOLogin] = React.useState(false);

  // Redirect the user with the new redirect URL which should contain the token
  const redirectUser = (finalRedirectURL) => {
    // Show spinner before redirect
    setSuccessRedirecting(true);

    // Wait 2 seconds before redirect in order for user to see
    // they are being redirected
    setTimeout(() => {
      // Redirect the user to redirect URL
      window.location.href = finalRedirectURL;
    }, 2000);
  };

  const verifySsoToken = (code, provider, err) => {
    if (err) {
      return setErrorMessage(err?.message || "Unkown error");
    }

    if (!code || !provider) {
      return setErrorMessage("Some details missing");
    }
    setErrorMessage(null);
    setSuccessMessage(null);
    setValidatingOTP(true);
    SP_API({ url: "auth/sso", data: { code, provider, redirectURL } })
      .then((response) => {
        if (
          response.data &&
          response.data.accounts &&
          response.data.accounts.length
        ) {
          setAccounts(response.data.accounts);
          setIsSSOLogin(true);
          // To bypass validate OTP handler
          setOTPrequestID(provider);
        } else if (response.data && response.data.token) {
          redirectUser(response.data.redirectUrl);
        } else {
          setErrorMessage(
            "An unknown error occurred validating sso token. Please try again."
          );
          setValidatingOTP(false);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err && err.status && err.data && err.data.message) {
          setErrorMessage(err.data.message);
          setOTP("");
        } else {
          setErrorMessage(
            "An unknown error occurred. Please try again."
          );
        }
        setValidatingOTP(false);
      });
  };

  const validateOTP = (account_selection = null, provider = "") => {
    setErrorMessage(null);
    setSuccessMessage(null);
    if (
      ((email || (phoneNumber && phoneNumber.length > 10)) &&
        (OTP &&
          OTP.length === 6)) ||
      OTPrequestID
    ) {
      setValidatingOTP(true);
      // Login with phone and OTP
      SP_API({
        url: "auth/validate/otp",
        data: {
          id: OTPrequestID,
          otp: OTP,
          ssoLogin: provider,
          redirectUrl: redirectURL,
          selection: account_selection
        }
      })
        .then((response) => {
          if (
            response.data &&
            response.data.accounts &&
            response.data.accounts.length
          ) {
            setAccounts(response.data.accounts);
          } else if (response.data && response.data.token) {
            redirectUser(response.data.redirectUrl);
          } else {
            setErrorMessage(
              "An unknown error occurred validating OTP. Please try again."
            );
            setValidatingOTP(false);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err && err.status && err.data && err.data.message) {
            setErrorMessage(err.data.message);
            setOTP("");
          } else {
            setErrorMessage(
              "An unknown error occurred. Please try again."
            );
          }
          setValidatingOTP(false);
        });
    } else {
      setErrorMessage("Some details missing");
    }
  };

  // Get query params
  const query = useQuery();

  useEffect(() => {
    // If redirect is specified in the URL, take it.
    // Else, take the default redirect URL from the config.json
    if (query.get("redirect")) {
      // Set the redirect URL as a cookie (no use case for now)
      const cookies = new Cookies();
      cookies.set("redirect", query.get("redirect"), { path: "/" });
      setRedirectURL(query.get("redirect"));
    }
    if (query.get("mode") && query.get("mode") === "logout") {
      setSuccessMessage("You have been logged out successfully.");
    }
    return false;
  }, []);

  return (
    <div className="container mx-auto px-4 h-full">
      <div className="flex content-center items-center justify-center h-full">
        <div className="w-full lg:w-4/12 md:w-6/12 px-3 login_modal">
          <div
            className="relative flex flex-col min-w-0 break-words w-full mb-2 shadow-lg rounded-lg bg-blueGray-200 border-0">
            <div className="flex-auto px-4 xl:px-10 lg:px-6 pt-0">
              <div className="text-blueGray-400 text-center mb-1 font-bold">
                <img
                  className="max-w-10/12 p-5 inline-block"
                  alt="SuperProcure"
                  src={config.customLogo ? require("../../assets/images/customLogo.png") : require("../../assets/images/logo_black.png")}
                ></img>
              </div>

              <LoginError message={errorMessage}></LoginError>
              <LoginSuccess
                message={successMessage}
              ></LoginSuccess>
              <div className="font-bold text-center">
                <FormattedMessage
                  id="auth.sign_in_account"
                  defaultMessage="Sign in to your account"
                  description="Sign in title"
                />
              </div>
              {successRedirecting ? (
                <div className="text-center justify-center space-x-2 mt-4">
                  <i
                    className="animate-spin text-xl fa-solid fa-circle-notch fa-spin text-sp-800"></i>
                  <br />
                  <FormattedMessage
                    id="auth.redirecting_to"
                    defaultMessage="Redirecting to"
                    description="Redirecting to text"
                  />{" "}
                  {new Url(redirectURL).hostname}
                </div>
              ) : accounts && accounts.length ? (
                <LoginAccounts
                  accounts={accounts}
                  setSuccessMessage={setSuccessMessage}
                  validateOTP={validateOTP}
                  OTPrequestID={OTPrequestID}
                  email={email}
                  isSSOLogin={isSSOLogin}
                  provider={provider}
                ></LoginAccounts>
              ) : (
                <LoginTabs
                  validateOTP={validateOTP}
                  setAccounts={setAccounts}
                  redirectURL={redirectURL}
                  errorMessage={errorMessage}
                  tabErrorMessage={tabErrorMessage}
                  setErrorMessage={setErrorMessage}
                  setTabErrorMessage={setTabErrorMessage}
                  setSuccessMessage={setSuccessMessage}
                  phoneNumber={phoneNumber}
                  email={email}
                  OTP={OTP}
                  setOTP={setOTP}
                  sendingOTP={sendingOTP}
                  validatingOTP={validatingOTP}
                  setSendingOTP={setSendingOTP}
                  setOTPrequestID={setOTPrequestID}
                  setPhoneNumber={setPhoneNumber}
                  setEmail={setEmail}
                  verifySsoToken={verifySsoToken}
                  provider={provider}
                  setProvider={setProvider}
                ></LoginTabs>
              )}
              <div className="text-blueGray-400 text-center mb-1 font-bold">
                {config.customLogo ? <img style={{width: '220px'}}
                  className="max-w-1/2 mx-auto p-5 inline-block"
                  alt="SuperProcure"
                  src={require("../../assets/images/Powered-by.png")}
                ></img> : ""}
              </div>
            </div>
          </div>
          <div className="flex flex-wrap relative">
            <div className="w-1/2">
              <a
                href="https://superprocure.freshservice.com/support/tickets/new"
                className="text-blueGray-200 font-bold"
              >
                <small>
                  <FormattedMessage
                    id="auth.trouble_logging_in"
                    defaultMessage="Trouble logging in?"
                    description="Log in error link"
                  />
                </small>
              </a>
            </div>
            <div className="w-1/2 text-right">
              <div className="switcher text-xs text-white">
                <LanguagePicker></LanguagePicker>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
