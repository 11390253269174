import { useMsal } from "@azure/msal-react";
import { useGoogleLogin } from "@react-oauth/google";
import { useState } from "react";
import config from "../../config/config.json";

export default function LoginSSO({ verifySsoToken, validatingOTP, provider, setProvider }) {
  const [processing, setProcessing] = useState(false);
  const { instance } = useMsal();

  const handleGoogleSuccess = (res) => {
    verifySsoToken(res.access_token, provider);
    setProcessing(false);
  };

  const handleError = (err) => {
    setProcessing(false);
    setProvider("");
    verifySsoToken(null, null, err);
  };

  const handleGoogleLogin = (e) => {
    setProcessing(true);
    setProvider("GOOGLE");
    googleLogin(e);
  };

  const handleMicrosoftLogin = () => {
    setProcessing(true);
    setProvider("MS");
    instance
      .loginPopup()
      .then((res) => {
        verifySsoToken(res.idToken, "MS");
        setProcessing(false);
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const googleLogin = useGoogleLogin({
    onSuccess: handleGoogleSuccess,
    onError: handleError,
    flow: "implicit"
  });

  const getGoogleSignInBtn = () => {
    if (config.blockGoogleLogin && (processing || validatingOTP) && provider !== "GOOGLE") {
      return null;
    }

    return (
      <button
        type="button"
        className="login-with-btn google-btn w-full"
        onClick={handleGoogleLogin}
        disabled={validatingOTP}
      >
        Sign in with Google
        {validatingOTP || processing ? (
          <span className="absolute right-0 text-sm mr-3">
                        {validatingOTP || processing ? (
                          <i className="fa-solid fa-circle-notch fa-spin text-sp-800"></i>
                        ) : (
                          <i className="fa-solid fa-check text-green-500"></i>
                        )}
                    </span>
        ) : null}
      </button>
    );
  };

  const getMicrosoftSignInBtn = () => {
    if (config.blockMSLogin && (processing || validatingOTP) && provider !== "MS") {
      return null;
    }

    return (
      <button
        type="button"
        className="mt-4 login-with-btn ms-btn w-full"
        onClick={handleMicrosoftLogin}
        disabled={validatingOTP}
      >
        Sign in with Microsoft
        {validatingOTP || processing ? (
          <span className="absolute right-0 text-sm mr-3">
                        {validatingOTP || processing ? (
                          <i className="fa-solid fa-circle-notch fa-spin text-sp-800"></i>
                        ) : (
                          <i className="fa-solid fa-check text-green-500"></i>
                        )}
                    </span>
        ) : null}
      </button>
    );
  };

  return (
    <div className="relative w-full py-3">
      {getGoogleSignInBtn()}
      {getMicrosoftSignInBtn()}
    </div>
  );
}
