import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FormattedMessage } from "react-intl";

import SP_API from "../../components/API.js";

export default function LoginPhone(props) {

  let { setPhoneNumber, sendOTP, sentOTP, sendingOTP, setErrorMessage } = props;

  const [fetchedCountries, setFetchedCountries] = React.useState([]);
  const countryCode = "+91";
  const [countries, setCountries] = React.useState([]);

  const changePhoneNumber = (phone_input, selected_country) => {
    if (selected_country && selected_country.dialCode && phone_input.length) {
      fetchedCountries.map(country => {
        if (selected_country.countryCode === country.code) {
          if (selected_country.dialCode.length + country.minPhoneNoLength <= phone_input.length) {
            setPhoneNumber(phone_input);
          } else {
            setPhoneNumber("");
          }
        }
        return false;
      });
    } else {
      setPhoneNumber(phone_input);
    }
  }
  React.useEffect(() => {
    function effectFunction() {
      SP_API({ url: "config/countries" }).then((response_json) => {
        if (response_json.data) {
          setErrorMessage(null);
          setCountries(response_json.data.map((country) => country.code));
          setFetchedCountries(response_json.data);
        } else {
          setErrorMessage("We are unable to fetch the countries list. Please reload the page.");
        }
      }).catch(err => {
        if (err && err.includes('timeout')) {
          setErrorMessage("Please check your internet connection and try again later.");
        } else {
          setErrorMessage("We are unable to fetch the countries list. Please reload the page.");
        }
        console.log(err);
      });
    }

    effectFunction();
  }, []);

  return (
    <div className="relative w-full py-3">
      <label
        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
        htmlFor="grid-phone"
      >
        <FormattedMessage
          id="auth.enter_mobile"
          defaultMessage="Enter mobile phone number"
          description="Enter mobile phone label"
        />
      </label>
      <div
        className={
          "border-0 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 " +
          (sentOTP || sendingOTP ? "bg-sp-200" : "bg-white")
        }
      >
        {sendingOTP || sentOTP ? (
          <span className="absolute right-0 text-lg mr-3 mt-1">
                {sendingOTP ? (
                  <i className="fa-solid fa-circle-notch fa-spin text-sp-800"></i>
                ) : (
                  <i className="fa-solid fa-check text-green-500"></i>
                )}
              </span>
        ) : null}
        {fetchedCountries && fetchedCountries.length ? (
          <PhoneInput
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: true
            }}
            autoFormat
            onChange={(phone_number, country) => changePhoneNumber(phone_number, country)}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                sendOTP();
              }
            }}
            value={countryCode}
            onlyCountries={countries}
            disabled={sentOTP || sendingOTP}
            enableSearch={false}
            countryCodeEditable={false}
            disableSearchIcon={true}
            inputClass="inp-cls_signin"
            containerClass="cust_signin border-none"
            dropdownClass="dropd_signin"
            placeholder="Phone number"
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
